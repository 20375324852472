<template>
  <div>
    <Card :parent_id="parent_id">
      <p class="ti">详细资料</p>
    </Card>

    <div class="content" style="margin-top: 20px">
      <p>籍贯</p>
      <div style="display: flex">
        <Workdata :data2="data2" @workData="work" />
      </div>
    </div>

    <div class="content">
      <p>体重</p>
      <div>
        <el-select v-model="stature" placeholder="请选择">
          <el-option
            v-for="item in statures"
            :key="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content">
      <p>体型</p>
      <div>
        <el-select v-model="shape" placeholder="请选择">
          <el-option
            v-for="item in shapes"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content">
      <p>星座</p>
      <div>
        <el-select v-model="constellation" placeholder="请选择">
          <el-option
            v-for="item in constellations"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content">
      <p>民族</p>
      <div>
        <el-select v-model="nation" placeholder="请选择">
          <el-option
            v-for="item in nations"
            :key="item.value"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content">
      <p>有没有孩子</p>
      <div>
        <el-select v-model="existsChildren" placeholder="请选择">
          <el-option
            v-for="item in existsChildrens"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content">
      <p>是否想要孩子</p>
      <div>
        <el-select v-model="wantChildren" placeholder="请选择">
          <el-option
            v-for="item in wantChildrens"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content">
      <p>何时结婚</p>
      <div>
        <el-select v-model="marriedTime" placeholder="请选择">
          <el-option
            v-for="item in marriedTimes"
            :key="item.value"
            :label="item.value"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 按钮 -->
    <Btn style="margin-top: 56px">
      <div class="continue" @click="bcjx">保存并继续</div>
      <div class="skip" @click="tg">跳过</div>
    </Btn>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Btn from "@/components/Card/Btn.vue";
import Workdata from "@/components/jgCity/index.vue";
import local from "@/api/common/local.js";
import { infocur, infoUpdate } from "@/api/login/login.js";
import { subTk } from "@/api/member/member.js";
export default {
  data() {
    return {
      parent_id: "", //组件传值id
      input: "",
      statures: [],
      stature: "",
      statureL: "",
      shapes: [],
      shape: "",
      UserSex: "",
      nations: [
        { id: "01", name: "汉族" },
        { id: "02", name: "蒙古族" },
        { id: "03", name: "回族" },
        { id: "04", name: "藏族" },
        { id: "05", name: "维吾尔族" },
        { id: "06", name: "苗族" },
        { id: "07", name: "彝族" },
        { id: "08", name: "壮族" },
        { id: "09", name: "布依族" },
        { id: "10", name: "朝鲜族" },
        { id: "11", name: "满族" },
        { id: "12", name: "侗族" },
        { id: "13", name: "瑶族" },
        { id: "14", name: "白族" },
        { id: "15", name: "土家族" },
        { id: "16", name: "哈尼族" },
        { id: "17", name: "哈萨克族" },
        { id: "18", name: "傣族" },
        { id: "19", name: "黎族" },
        { id: "20", name: "傈僳族" },
        { id: "21", name: "佤族" },
        { id: "22", name: "畲族" },
        { id: "23", name: "高山族" },
        { id: "24", name: "拉祜族" },
        { id: "25", name: "水族" },
        { id: "26", name: "东乡族" },
        { id: "27", name: "纳西族" },
        { id: "28", name: "景颇族" },
        { id: "29", name: "柯尔克孜族" },
        { id: "30", name: "土族" },
        { id: "31", name: "达斡尔族" },
        { id: "32", name: "仫佬族" },
        { id: "33", name: "羌族" },
        { id: "34", name: "布朗族" },
        { id: "35", name: "撒拉族" },
        { id: "36", name: "毛难族" },
        { id: "37", name: "仡佬族" },
        { id: "38", name: "锡伯族" },
        { id: "39", name: "阿昌族" },
        { id: "40", name: "普米族" },
        { id: "41", name: "塔吉克族" },
        { id: "42", name: "怒族" },
        { id: "43", name: "乌孜别克族" },
        { id: "44", name: "俄罗斯族" },
        { id: "45", name: "鄂温克族" },
        { id: "46", name: "崩龙族" },
        { id: "47", name: "保安族" },
        { id: "48", name: "裕固族" },
        { id: "49", name: "京族" },
        { id: "50", name: "塔塔尔族" },
        { id: "51", name: "独龙族" },
        { id: "52", name: "鄂伦春族" },
        { id: "53", name: "赫哲族" },
        { id: "54", name: "门巴族" },
        { id: "55", name: "珞巴族" },
        { id: "56", name: "基诺族" },
        { id: "57", name: "国外" },
      ],
      nation: "",
      existsChildrens: [
        {
          value: "没有孩子",
          label: 0,
        },
        {
          value: "有孩子且住在一起",
          label: 1,
        },
        {
          value: "有孩子且偶尔会在一起住",
          label: 2,
        },
        {
          value: "有孩子但不在身边",
          label: 3,
        },
      ],
      existsChildren: "",
      constellations: [
        {
          value: "牡羊座(03.21-04.20)",
          label: 0,
        },
        {
          value: "金牛座(04.21-05.20)",
          label: 1,
        },
        {
          value: "双子座(05.21-06.21)",
          label: 2,
        },
        {
          value: "巨蟹座(06.22-07.22)",
          label: 3,
        },
        {
          value: "狮子座(07.23-08.22)",
          label: 4,
        },
        {
          value: "处女座(08.23-09.22)",
          label: 5,
        },
        {
          value: "天秤座(09.23-10.22)",
          label: 6,
        },
        {
          value: "天蝎座(10.23-11.21)",
          label: 7,
        },
        {
          value: "射手座(11.22-12.21)",
          label: 8,
        },
        {
          value: "摩羯座(12.22-01.19)",
          label: 9,
        },
        {
          value: "水瓶座(01.20-02.19)",
          label: 10,
        },
        {
          value: "双鱼座(02.20-03.20)",
          label: 11,
        },
      ],
      constellation: "",
      wantChildrens: [
        {
          value: "视情况而定",
          label: 0,
        },
        {
          value: "想要孩子",
          label: 1,
        },
        {
          value: "不想要孩子",
          label: 2,
        },
        {
          value: "以后再告诉你",
          label: 4,
        },
      ],
      wantChildren: "",
      marriedTimes: [
        {
          value: "认同闪婚",
          label: 0,
        },
        {
          value: "一年内",
          label: 1,
        },
        {
          value: "两年内",
          label: 2,
        },
        {
          value: "三年内",
          label: 3,
        },
        {
          value: "时机成熟就结婚",
          label: 4,
        },
      ],
      marriedTime: "",
      workregion: "",
      data2: null,
    };
  },
  components: {
    Card,
    Btn,
    Workdata,
  },
  created() {
    this.sjcsh();
    this.go();
    this.Shcsh();
  },
  methods: {
    work(data) {
      console.log("工作地方", data);
      this.workregion = data;
    },
    sjcsh() {
      this.statures.push({
        value: "30kg以下",
        label: 29,
      });
      for (let i = 30; i <= 130; i++) {
        this.statures.push({
          value: i + "kg",
          label: i,
        });
      }
      this.statures.push({
        value: "130kg以上",
        label: 131,
      });
    },
    Shcsh() {
      const ManShap = [
        {
          value: "保密",
          label: 0,
        },
        {
          value: "一般",
          label: 1,
        },
        {
          value: "瘦长",
          label: 2,
        },
        {
          value: "运动员型",
          label: 3,
        },
        {
          value: "比较胖",
          label: 4,
        },
        {
          value: "体格魁梧",
          label: 5,
        },
        {
          value: "壮实",
          label: 6,
        },
      ];

      const WomenShap = [
        {
          value: "保密",
          label: 0,
        },
        {
          value: "一般",
          label: 1,
        },
        {
          value: "瘦长",
          label: 2,
        },
        {
          value: "苗条",
          label: 3,
        },
        {
          value: "高大美丽",
          label: 4,
        },
        {
          value: "丰满",
          label: 5,
        },
        {
          value: "富线条美",
          label: 6,
        },
      ];

      if (this.UserSex == 0) {
        this.shapes = ManShap;
      } else if (this.UserSex == 1) {
        this.shapes = WomenShap;
      }
    },
    //初始化数据
    async go() {
      let a = local.get("access_token");
      const { code, data } = await infocur(a);
      if (code == 0) {
        console.log('_______',data.user.nativePlaceCityId);
        this.data2 = {
          nativePlaceCityId:
            data.user.nativePlaceCityId == ""
              ? (data.user.nativePlaceCityId = null)
              : data.user.nativePlaceCityId,
          nativePlaceCityName:
            data.user.nativePlaceCityName == ""
              ? (data.user.nativePlaceCityName = null)
              : data.user.nativePlaceCityName,
          nativePlaceDistrictId:
            data.user.nativePlaceDistrictId == ""
              ? (data.user.nativePlaceDistrictId = null)
              : data.user.nativePlaceDistrictId,
          nativePlaceDistrictName:
            data.user.nativePlaceDistrictName == ""
              ? (data.user.nativePlaceDistrictName = null)
              : data.user.nativePlaceDistrictName,
          nativePlaceProvinceId:
            data.user.nativePlaceProvinceId == ""
              ? (data.user.nativePlaceProvinceId = null)
              : data.user.nativePlaceProvinceId,
          nativePlaceProvinceName:
            data.user.nativePlaceProvinceName == ""
              ? (data.user.nativePlaceProvinceName = null)
              : data.user.nativePlaceProvinceName,
        };
//         this.data2={
// nativePlaceCityId:null
//         }
        this.UserSex = data.user.sex;
        this.stature = data.user.weight;
        this.parent_id = data.user.id;

        if (this.statureL == null) {
          this.stature == null;
        } else {
          this.statures.forEach((item) => {
            if (this.stature == item.label) {
              this.stature = item.value;
            }
          });
        }
        const ManShap = [
          {
            value: "保密",
            label: 0,
          },
          {
            value: "一般",
            label: 1,
          },
          {
            value: "瘦长",
            label: 2,
          },
          {
            value: "运动员型",
            label: 3,
          },
          {
            value: "比较胖",
            label: 4,
          },
          {
            value: "体格魁梧",
            label: 5,
          },
          {
            value: "壮实",
            label: 6,
          },
        ];

        const WomenShap = [
          {
            value: "保密",
            label: 0,
          },
          {
            value: "一般",
            label: 1,
          },
          {
            value: "瘦长",
            label: 2,
          },
          {
            value: "苗条",
            label: 7,
          },
          {
            value: "高大美丽",
            label: 8,
          },
          {
            value: "丰满",
            label: 9,
          },
          {
            value: "富线条美",
            label: 10,
          },
        ];

        if (this.UserSex == 0) {
          this.shapes = ManShap;
        } else if (this.UserSex == 1) {
          this.shapes = WomenShap;
        }
        //体型
        this.shape = data.user.shape;
        if (this.shape == null) {
          this.shape == null;
        } else {
          this.shapes.forEach((item) => {
            if (this.shape == item.label) {
              this.shape = item.value;
            }
          });
        }

        //星座
        this.constellation = data.user.constellatory;
        if (this.constellation == null) {
          this.constellation == null;
        } else {
          this.constellations.forEach((item) => {
            if (this.constellation == item.label) {
              this.constellation = item.value;
            }
          });
        }

        //是否有孩子
        this.existsChildren = data.user.existsChildren;
        if (this.existsChildren == null) {
          this.existsChildren == null;
        } else {
          this.existsChildrens.forEach((item) => {
            if (this.existsChildren == item.label) {
              this.existsChildren = item.value;
            }
          });
        }

        //是否想要孩子
        this.wantChildren = data.user.wantChildren;
        if (this.wantChildren == null) {
          this.wantChildren == null;
        } else {
          this.wantChildrens.forEach((item) => {
            if (this.wantChildren == item.label) {
              this.wantChildren = item.value;
            }
          });
        }

        //何时结婚
        this.marriedTime = data.user.marriedTime;
        if (this.marriedTime == null) {
          this.marriedTime == null;
        } else {
          this.marriedTimes.forEach((item) => {
            if (this.marriedTime == item.label) {
              this.marriedTime = item.value;
            }
          });
        }
        // 民族
        this.nation = data.user.nation;
        console.log("1", this.constellation, "2", this.shape);
      }
    },
    async bcjx() {
      //保存调接口
      //体重
      this.statures.forEach((item) => {
        if (this.stature == item.value) {
          this.statureL = item.label;
        }
      });
      this.shapes.forEach((item) => {
        if (this.shape == item.value) {
          this.shape = item.label;
        }
      });
      this.constellations.forEach((item) => {
        if (this.constellation == item.value) {
          this.constellation = item.label;
        }
      });

      this.wantChildrens.forEach((item) => {
        if (this.wantChildren == item.value) {
          this.wantChildren = item.label;
        }
      });
      this.existsChildrens.forEach((item) => {
        if (this.existsChildren == item.value) {
          this.existsChildren = item.label;
        }
      });

      this.marriedTimes.forEach((item) => {
        if (this.marriedTime == item.value) {
          this.marriedTime = item.label;
        }
      });
      let a = {
        weight: this.statureL,
        shape: this.shape,
        constellatory: this.constellation,
        wantChildren: this.wantChildren,
        existsChildren: this.existsChildren,
        nation: this.nation,
        marriedTime: this.marriedTime,
        // 城市
        nativePlaceCityId: this.workregion.cityId,
        nativePlaceCityName: this.workregion.city,
        nativePlaceDistrictId: this.workregion.countyId,
        nativePlaceDistrictName: this.workregion.county,
        nativePlaceProvinceId: this.workregion.provinceId,
        nativePlaceProvinceName: this.workregion.province,
      };
      const { code } = await infoUpdate(a);
      if (code == 0) {
        const { code, data } = await subTk();
        if (code == 0) {
          local.SessionSet("sub_tk", data);
        }
        this.$message({
          message: "修改成功",
          type: "success",
          offset: 100,
        });
        setTimeout(() => {
          //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
          this.$router.push({
            path: `/n/user/_workLife`,
          });
          //延迟时间：3秒
        }, 0);
      } else {
        const { code, data } = await subTk();
        if (code == 0) {
          local.SessionSet("sub_tk", data);
        }
      }
    },
    //跳过
    tg() {
      this.$router.push({
        path: `/n/user/_workLife`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  height: 40px;
  align-items: center;
  margin-bottom: 18px;
  width: 880px;
  p {
    width: 116px;
    font-size: 16px;
    color: #666666;
  }
}
/deep/.el-input__inner {
  background-color: #f5f5f5 !important;
  border: none !important;
}
</style>